// let initMap = null;

Zepto(function($) {

    /* ========================
    	Zepto Extentions
    ========================= */

    $.extend($.fn, {
        animateCss: function(animationName) {
            var animationEnd = "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
            this.addClass("animated " + animationName).one(animationEnd, function() {
                $(this).removeClass("animated " + animationName);
            });
        }
    });

    let defaultLanguage = 'English'; // or Español

    /* ==========================
    	Initialization
    =========================== */

    function activate() {
        let activeLanguage = checkStorageKey('hellobaby_language') || defaultLanguage;
        let mobileLanguageBtn = $('#MobileLanguageBtn');
        let mainPage = $('#main');

        let enLanguageBtn = $('#ENLanguageBtn');
        let esLanguageBtn = $('#ESLanguageBtn');

        if (activeLanguage === 'Español') {
            mainPage.addClass('espanol');
            mainPage.addClass('hide-english');
            mobileLanguageBtn.html('English');
        } else {
            mainPage.addClass('english');
            mobileLanguageBtn.html('Español');
        }

        enLanguageBtn.on('click', () => {
            activeLanguage = 'English';
            mainPage.removeClass('espanol');
            mainPage.addClass('english');
            mobileLanguageBtn.html('Español');

            setStorageValue('hellobaby_language', activeLanguage);
        });

        esLanguageBtn.on('click', () => {
            activeLanguage = 'Español';
            mainPage.removeClass('english');
            mainPage.addClass('hide-english');
            mainPage.addClass('espanol');
            mobileLanguageBtn.html('English');

            setStorageValue('hellobaby_language', activeLanguage);
        });

        let mobileLanguageBtnClickListener = (event) => {
            event.preventDefault();

            if (activeLanguage === 'Español') {
                activeLanguage = 'English';
                mainPage.removeClass('espanol');
                mainPage.addClass('english');
                mobileLanguageBtn.html('Español');
            } else {
                activeLanguage = 'Español';
                mainPage.removeClass('english');
                mainPage.addClass('espanol');
                mobileLanguageBtn.html('English');
            }

            setStorageValue('hellobaby_language', activeLanguage);

            return false;
        };

        mobileLanguageBtn.on('click', mobileLanguageBtnClickListener);

        let url = window.location;
        let currentPage = getCurrentPage(url);

        if (currentPage === 'index' || currentPage === 'clients/EHG/HelloBaby/A7DJE93J2HD923/index') {
            setScrollReveal();
        }

        if (currentPage === 'reset') {
            let userAgent = navigator.userAgent;

            $('#NewPassword').on('keyup', isLength);
            $('#ConPassword').on('keyup', isEqual);
            $('#reset-form').on('submit', submitHandling);
        }
    }

    function isLength(event) {
        event.preventDefault();

        let newPass = $('#NewPassword').val();
        let conPass = $('#ConPassword').val();

        $('#NewPasswordGroup').removeClass('error-active');
        $('#ConPasswordGroup').removeClass('error-active');
        

        if (newPass.length > 7 && conPass.length < 1) {
            $('#NewPassHint').html(''); 
        }
    }

    function isEqual() {
        let newPass = $('#NewPassword').val();
        let conPass = $('#ConPassword').val();

        $('#NewPasswordGroup').removeClass('error-active');
        $('#ConPasswordGroup').removeClass('error-active');

        if (newPass === conPass) {
            $('#ConPasswordGroup').addClass('success-active');
            $('#ConPassHint').html('');
        }
    }

    function submitHandling(event) {
        event.preventDefault();

        let formValid = [];
        let newPass = $('#NewPassword').val();
        let conPass = $('#ConPassword').val();

        if (newPass.length < 7) {
            triggerError({
                targetElement: $('#NewPassHint'),
                type: 'length',
            });

            $('#NewPasswordGroup').addClass('error-active');

          return false;
        }

        if (newPass !== conPass) {
            triggerError({
                targetElement: $('#ConPassHint'),
                type: 'match',
            });

            $('#ConPasswordGroup').addClass('error-active');
          return false;
        }

        putPasswordReset();

        return true;
      }

    /**
     * Set Scroll Reveal: Fades in elements on anchor hit
     *
     */
    function setScrollReveal() {
        window.sr = ScrollReveal();
        sr.reveal('.topic-info-container', { duration: 600 });
        sr.reveal('.app-example--left', {
            duration: 1000,
            origin: 'left',
            distance: '100px',
            rotate: { y: 50 }
        });
        sr.reveal('.app-example--right', {
            duration: 1200,
            origin: 'right',
            distance: '100px',
            rotate: { y: 50 }
        });
    }

    let getCurrentPage = (url) => {
        url = url || false;

        if (!url) {

            return url;
        }

        let currentPage = false;

        if (url.pathname === "/") {
            currentPage = "index";
        } else {
            currentPage = url.pathname;
        }

        if (currentPage.indexOf(".html") !== -1) {
            currentPage = currentPage.replace(/.html/gi, "");
        }

        if (currentPage.indexOf("/") !== -1) {
            currentPage = currentPage.replace("/", "");
        }

        return currentPage;
    };

    /* ========================
    	API Calls
    ========================= */

    function putPasswordReset() {
        let resetSuccess = () => {
            $('#success-card').show();
            $('#reset-card').hide();
        };

        let formData = JSON.stringify({
            password: $('#NewPassword').val(),
            token: getURLToken(),
        });

        let baseURL = 'https://api.holabebe.net';

        // I have no clue why the success method is not triggering in the ajax call, so this has to be done...
        resetSuccess();

        if (formData) {
            $.ajax({
                type: 'PUT',
                contentType: 'application/json',
                url: baseURL + '/user/reset_password',
                dataType: 'json',
                data: formData,
                success: resetSuccess,
                error: triggerError
            });
        }
    }

    /* ========================
    	Error States
    ========================= */

    let triggerError = ({ targetElement, type, status }) => {
        let errorMessage = '';

        if (targetElement === undefined) {
            return false;
        }

        if (status === 500) {
            errorMessage = 'There was an internal error while attempting to reset your password.';

            targetElement.html(errorMessage);
        } else {
            switch (type) {
                case 'length':
                    errorMessage = 'The length of your password needs to be at least 8 characters.';
                    break;
                case 'match':
                    errorMessage = 'The passwords you have entered do not match.';
                    break;
                default:
                    errorMessage = 'An error has occured.';
            }

            targetElement.html(errorMessage);
        }

        targetElement.addClass('error-active');
    };

    /* ========================
    	URL Parsing
    ========================= */

    let getURLToken = () => {
        let url = document.location.search;
        return url.replace('?token=', '');
    };


    /* ========================
    	Storage
    ========================= */

    let checkStorageKey = (key) => {
        return sessionStorage.getItem(key);
    };

    let setStorageValue = (key, value) => {
        sessionStorage.setItem(key, value);
    };

    activate();
});